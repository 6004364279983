import Vue from 'vue'

import compassApi from '@/services/compass-api'
import Building from '@/models/Building'

// initial state
const state = {
  buildings: [],
  activeBuildingId: null,
}

// getters
const getters = {
  getById(state) {
    return (buildingId) => {
      return state.buildings.find((b) => b.id === buildingId)
    }
  },
}

// actions
const actions = {
  /*
   * Loaders
   */
  async loadDetailsById({ state, commit, dispatch }, buildingId) {
    const building = state.buildings.find((b) => b.id === buildingId)
    if (!building || (!building.__loading && !building.__loaded)) {
      // Only load if not already loaded or currently loading
      commit('SET_DETAILS_LOADING', buildingId)
      const details = await compassApi.call(`/buildings/${buildingId}`)
      commit('SET_DETAILS_LOADED', { buildingId, details })
      // Update permissions for building
      dispatch('permissions/setBuildingPermissions', details, { root: true })
    } else if (building) {
      dispatch('permissions/setBuildingPermissions', building, { root: true })
    }
  },
  async refreshDetailsById({ commit, dispatch }, buildingId) {
    commit('SET_DETAILS_LOADING', buildingId)
    const details = await compassApi.call(`/buildings/${buildingId}`)
    commit('SET_DETAILS_LOADED', { buildingId, details })
    // Update permissions for building
    dispatch('permissions/setBuildingPermissions', details, { root: true })
    // Update chart data
    dispatch('chartData/refresh', null, { root: true })
  },
  async setActiveBuildingId({ commit }, buildingId) {
    commit('SET_ACTIVE_BUILDING_ID', { buildingId })
  },
  async markDirty({ commit, dispatch }, buildingId) {
    if (buildingId) {
      if (buildingId === state.activeBuildingId) {
        dispatch('refreshDetailsById', buildingId)
      } else {
        commit('RESET_DETAILS', { buildingIds: [buildingId] })
      }
    }
  },
  /*
   * Create, modify and delete buildings
   */
  async addBuilding({ dispatch }, { building, portfolioId }) {
    const buildingResponse = await compassApi.call(`/portfolios/${portfolioId}/buildings`, 'POST', building)
    dispatch('portfolio/refreshDetailsById', portfolioId, { root: true })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('portfolio/loadAll', null, { root: true })
    return new Building(buildingResponse)
  },
  async addBuildingsAndTagGroups({ dispatch }, { buildings, tagGroups, portfolioId }) {
    await compassApi.call(`/import/${portfolioId}/buildings`, 'POST', { buildings, tagGroups })
    dispatch('portfolio/refreshDetailsById', portfolioId, { root: true })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('portfolio/loadAll', null, { root: true })
  },
  async deleteBuilding({ dispatch }, { buildingId, portfolioId }) {
    await compassApi.call(`/buildings/${buildingId}`, 'DELETE')
    dispatch('portfolio/refreshDetailsById', portfolioId, { root: true })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('portfolio/loadAll', null, { root: true })
  },
  async deleteBuildings({ dispatch }, { buildingIds, portfolioId }) {
    for (let i = 0; i < buildingIds.length; i++) {
      await compassApi.call(`/buildings/${buildingIds[i]}`, 'DELETE')
    }
    dispatch('portfolio/refreshDetailsById', portfolioId, { root: true })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
    dispatch('portfolio/loadAll', null, { root: true })
  },
  async updateBuilding({ state, dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
  },
  async updateBuildingStatus({ dispatch }, data) {
    const { id, status } = data
    await compassApi.call(`/buildings/${id}/status`, 'PUT', { status })
    dispatch('refreshDetailsById', id)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
  },
  async updateBuildingElectricityGridTariff({ dispatch }, data) {
    const { id, category, customValues } = data
    await compassApi.call(`/buildings/${id}/grid_tariff`, 'PUT', {
      category,
      customValues,
    })
    dispatch('refreshDetailsById', id)
  },
  async updateBuildingElectricityFeedinTariff({ dispatch }, data) {
    const { id, customValues } = data
    await compassApi.call(`/buildings/${id}/feedin_tariff`, 'PUT', { customValues })
    dispatch('refreshDetailsById', id)
  },
  /*
   * Operations on the building
   */
  async updateGisData({ state, dispatch }, { buildingId, portfolioId }) {
    await compassApi.call(`/buildings/${buildingId}/update_gis_data`, 'POST')
    dispatch('refreshDetailsById', buildingId)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
  },
  async simulateBuilding({ state, dispatch }, { buildingId, portfolioId }) {
    await compassApi.call(`/buildings/${buildingId}/simulate`, 'POST')
    dispatch('refreshDetailsById', buildingId)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
  },
  async queueSimulationBuilding({ state, dispatch }, { buildingId, portfolioId }) {
    await compassApi.call(`/buildings/${buildingId}/queue-simulation`, 'POST')
    dispatch('refreshDetailsById', buildingId)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
  },
  async updateLatestReida({ state, dispatch }, { buildingId, portfolioId }) {
    await compassApi.call(`/buildings/${buildingId}/update-latest-reida`, 'POST')
    dispatch('refreshDetailsById', buildingId)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
  },
  async sanityCheckBuilding({ state, dispatch }, { buildingId, portfolioId }) {
    await compassApi.call(`/buildings/${buildingId}/sanitycheck`, 'POST')
    dispatch('refreshDetailsById', buildingId)
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
  },
  /*
   * Edit tags
   */
  async setTag({ dispatch }, { buildingId, groupId, tagId }) {
    await compassApi.call(`/buildings/${buildingId}/tags/${groupId}`, 'PUT', {
      tag_id: tagId,
    })
    dispatch('refreshDetailsById', buildingId)
  },
  /*
   * Trigger GIS data refresh
   */
  async queryRoofTiles({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/roof`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  async queryGwr({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/gwr`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  async queryCadastre({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/cadastre`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  async queryGeothermal({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/geothermal`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  async queryEnergyGrids({ dispatch }, fields) {
    await compassApi.call(`/buildings/${fields.id}/energy_grids`, 'PUT', fields)
    dispatch('refreshDetailsById', fields.id)
  },
  /*
   * Create, modify and delete Measures
   */
  async addMeasure({ dispatch }, measure) {
    await compassApi.call(`/measures`, 'POST', measure)
    dispatch('refreshDetailsById', measure.building_id)
  },
  async updateMeasure({ dispatch }, measure) {
    await compassApi.call(`/measures/${measure.id}`, 'PUT', measure)
    dispatch('refreshDetailsById', measure.building_id)
  },
  async completeMeasure({ dispatch }, measure) {
    await compassApi.call(`/measures/${measure.id}/complete`, 'POST')
    dispatch('refreshDetailsById', measure.building_id)
  },
  async deleteMeasure({ dispatch }, measure) {
    await compassApi.call(`/measures/${measure.id}`, 'DELETE')
    dispatch('refreshDetailsById', measure.building_id)
  },
  /*
   * Create, modify and delete Meters and MeterValues
   */
  // Meters
  async addMeter({ dispatch }, meter) {
    const result = await compassApi.call(`/meters`, 'POST', meter)
    dispatch('refreshDetailsById', meter.building_id)
    return result
  },
  async updateMeter({ dispatch }, meter) {
    await compassApi.call(`/meters/${meter.id}`, 'PUT', meter)
    dispatch('refreshDetailsById', meter.building_id)
  },
  async deleteMeter({ dispatch }, meter) {
    await compassApi.call(`/meters/${meter.id}`, 'DELETE')
    dispatch('refreshDetailsById', meter.building_id)
  },
  // Meter values
  async addMeterValue({ dispatch }, { buildingId, value }) {
    await compassApi.call(`/meters/${value.meter_id}/values`, 'POST', value)
    dispatch('refreshDetailsById', buildingId)
  },
  async addMeterValues({ dispatch }, { values, portfolioId }) {
    await compassApi.call(`/import/${portfolioId}/metervalues`, 'POST', values)
    dispatch('portfolio/refreshDetailsById', portfolioId, { root: true })
    dispatch('portfolioBuildingsForList/refresh', null, { root: true })
  },
  async updateMeterValue({ dispatch }, { buildingId, value }) {
    await compassApi.call(`/meters/${value.meter_id}/values/${value.id}`, 'PUT', value)
    dispatch('refreshDetailsById', buildingId)
  },
  async deleteMeterValue({ dispatch }, { buildingId, value }) {
    await compassApi.call(`/meters/${value.meter_id}/values/${value.id}`, 'DELETE')
    dispatch('refreshDetailsById', buildingId)
  },
}

// mutations
const mutations = {
  SET_DETAILS_LOADING(state, { buildingId }) {
    let buildings = state.buildings.filter((b) => b.id !== buildingId)
    Vue.set(state, 'buildings', [
      ...buildings,
      new Building({
        id: buildingId,
        __loading: true,
        __loaded: false,
      }),
    ])
  },
  SET_DETAILS_LOADED(state, { buildingId, details }) {
    let buildings = state.buildings.filter((b) => b.id !== buildingId)
    Vue.set(state, 'buildings', [
      ...buildings,
      new Building({
        ...details,
        __loading: false,
        __loaded: true,
      }),
    ])
  },
  RESET_DETAILS(state, { buildingIds }) {
    const buildings = state.buildings.filter((b) => !buildingIds.includes(b.id))
    Vue.set(state, 'buildings', [...buildings])
  },
  SET_ACTIVE_BUILDING_ID(state, { buildingId }) {
    state.activeBuildingId = buildingId
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
