// Store for UI settings and preferences

// Variables saved in local storage:
const state = {
  // Portfolio sidebar
  portfolioMapMode: 'satellite', // 'satellite' or 'map'
  portfolioKpiMode: 'performance', // 'performance', 'overview', 'energy', 'emissions', or 'data' // TODO: 'reduction-path'
  portfolioSelectedKpiTabIndeces: JSON.parse(localStorage.getItem('portfolioSelectedKpiTabIndeces')) || {},
  portfolioKpiDisplayMode: 'count', // 'count' or 'area'
  // Portfolio building list
  portfolioListDisplayMode: 'cards', // 'cards' or 'table'
  // Building sidebar
  buildingMapMode: 'satellite', // 'satellite' or 'map'
  buildingKpiMode: 'performance', // 'performance' // TODO: 'energy', 'emissions', 'pv', 'reduction-path' and 'remarks'
  // Reduction path charts
  selectedTabIndices: JSON.parse(localStorage.getItem('selectedTabIndices')) || {},
}

// Getters
const getters = {
  getPortfolioMapMode(state) {
    return () => {
      return state.portfolioMapMode
    }
  },
  getPortfolioKpiMode(state) {
    return () => {
      return state.portfolioKpiMode
    }
  },
  getPortfolioKpiTabIndex(state) {
    return (group) => {
      return state.portfolioSelectedKpiTabIndeces[group] || 0
    }
  },
  getPortfolioKpiDisplayMode(state) {
    return () => {
      return state.portfolioKpiDisplayMode
    }
  },
  getPortfolioListDisplayMode(state) {
    return () => {
      return state.portfolioListDisplayMode
    }
  },
  getBuildingMapMode(state) {
    return () => {
      return state.buildingMapMode
    }
  },
  getBuildingKpiMode(state) {
    return () => {
      return state.buildingKpiMode
    }
  },
  getSelectedTabIndex(state) {
    return (group) => {
      return state.selectedTabIndices[group] || 0
    }
  },
}

// Actions
const actions = {
  setPortfolioMapMode({ commit }, mode) {
    commit('SET_PORTFOLIO_MAP_MODE', mode)
  },
  setPortfolioKpiMode({ commit }, mode) {
    commit('SET_PORTFOLIO_KPI_MODE', mode)
  },
  setPortfolioSelectedKpiTabIndex({ commit }, { group, index }) {
    commit('SET_PORTFOLIO_KPI_TAB_INDEX', { group, index })
  },
  setPortfolioKpiDisplayMode({ commit }, mode) {
    commit('SET_PORTFOLIO_KPI_DISPLAY_MODE', mode)
  },
  setPortfolioListDisplayMode({ commit }, mode) {
    commit('SET_PORTFOLIO_LIST_DISPLAY_MODE', mode)
  },
  setBuildingMapMode({ commit }, mode) {
    commit('SET_BUILDING_MAP_MODE', mode)
  },
  setBuildingKpiMode({ commit }, mode) {
    commit('SET_BUILDING_KPI_MODE', mode)
  },
  setSelectedTabIndex({ commit }, { group, index }) {
    commit('SET_SELECTED_TAB_INDEX', { group, index })
  },
}

// Mutations
const mutations = {
  SET_PORTFOLIO_MAP_MODE(state, mode) {
    state.portfolioMapMode = mode
    localStorage.setItem('portfolioMapMode', mode)
  },
  SET_PORTFOLIO_KPI_MODE(state, mode) {
    state.portfolioKpiMode = mode
    localStorage.setItem('portfolioKpiMode', mode)
  },
  SET_PORTFOLIO_KPI_TAB_INDEX(state, { group, index }) {
    // Brute force rewrite of the whole object to trigger reactivity
    // Otherwise, the computed property in the component won't be updated (nested objects are not reactive)
    state.portfolioSelectedKpiTabIndeces = { ...state.portfolioSelectedKpiTabIndeces, [group]: index }
    localStorage.setItem('portfolioSelectedKpiTabIndeces', JSON.stringify(state.portfolioSelectedKpiTabIndeces))
  },
  SET_PORTFOLIO_KPI_DISPLAY_MODE(state, mode) {
    state.portfolioKpiDisplayMode = mode
    localStorage.setItem('portfolioKpiDisplayMode', mode)
  },
  SET_PORTFOLIO_LIST_DISPLAY_MODE(state, mode) {
    state.portfolioListDisplayMode = mode
    localStorage.setItem('portfolioListDisplayMode', mode)
  },
  SET_BUILDING_MAP_MODE(state, mode) {
    state.buildingMapMode = mode
    localStorage.setItem('buildingMapMode', mode)
  },
  SET_BUILDING_KPI_MODE(state, mode) {
    state.buildingKpiMode = mode
    localStorage.setItem('buildingKpiMode', mode)
  },
  SET_SELECTED_TAB_INDEX(state, { group, index }) {
    // Brute force rewrite of the whole object to trigger reactivity
    // Otherwise, the computed property in the component won't be updated (nested objects are not reactive)
    state.selectedTabIndices = { ...state.selectedTabIndices, [group]: index }
    localStorage.setItem('selectedTabIndices', JSON.stringify(state.selectedTabIndices))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
