import _ from 'lodash'
import compassApi from '@/services/compass-api'

export const genericLoadWithPortfolioAndQuery = async ({ commit, state, rootState, portfolioId, urlFct }) => {
  const newQuery = rootState.query
  // Only do a new request if the context has changed, or we're loaded currently
  if (
    portfolioId &&
    (!state.loaded ||
      state.portfolioId !== portfolioId ||
      (state.query && !_.isEqual(JSON.parse(state.query), newQuery)))
  ) {
    const requestId = `${Math.random().toString(36).slice(2, 7)}-${Date.now()}`
    commit('loading')
    // Reset when switched portfolio
    if (state.portfolioId !== portfolioId) {
      commit('reset')
    }
    const query = JSON.stringify(newQuery)
    commit('setContext', { portfolioId, query, requestId })
    const data = await compassApi.call(urlFct(portfolioId, query))
    // Only apply loaded, when it's the last request
    if (state.latestRequestId === requestId) {
      commit('loaded', { data })
    }
  }
}

export const genericRefreshWithPortfolioAndQuery = async ({ commit, state, rootState, urlFct }) => {
  const newQuery = rootState.query
  const portfolioId = state.portfolioId
  // Only do a new request if the context has changed, or we're loaded currently
  if (
    portfolioId &&
    (!state.loaded ||
      state.portfolioId !== portfolioId ||
      (state.query && !_.isEqual(JSON.parse(state.query), newQuery)))
  ) {
    const requestId = `${Math.random().toString(36).slice(2, 7)}-${Date.now()}`
    commit('loading')
    const query = JSON.stringify(newQuery)
    commit('setContext', { portfolioId, query, requestId })
    const data = await compassApi.call(urlFct(portfolioId, query))
    // Only apply loaded, when it's the last request
    if (state.latestRequestId === requestId) {
      commit('loaded', { data })
    }
  }
}
